ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const DialogController = require("@plex/ux/Dialogs/plex-dialog-controller");
const buildUrl = require("@plex/ux/Core/plex-navigate").buildUrl;

// glossary lookup is injected into the view - not sure why
const plex = window.plex;

const LoginController = function () {
  // constructor
};
const loginElementId = "loginPageContainer";

LoginController.prototype = {
  init: function () {
    const self = this;

    ko.applyBindings(self, $("#" + loginElementId)[0]);
  },

  authenticate: function () {
    return quick_Validate();
  },

  launchAbout: function () {
    open("http://www.plex.com");
  },

  launchHelp: function () {
    launchDialog("/Login/ViewHelpDialog");
  },

  launchSetup: function () {
    launchDialog("/Login/ViewSetupDialog");
  },

  launchUsePolicy: function () {
    launchDialog("/Login/ViewUsePolicyDialog");
  },

  navigateToIam: function () {
    plex.navigate("/sso" + window.location.search);
  }
};

$(() => {
  // For IE9's sake (doesn't support autofocus), focus the User ID element onload.
  const userIdEl = document.getElementById("userId");
  userIdEl && userIdEl.focus();

  // Initialize the control group focus prettiness.
  const plexFormContent = $(".plex-form-content");
  if (plexFormContent.length) {
    $(".plex-form-content")
      .on("focus", ":input", function () {
        $(this).closest(".plex-control-group").addClass("plex-control-focus");
      })
      .on("blur", ":input", function () {
        $(this).closest(".plex-control-group").removeClass("plex-control-focus");
      })
      .on("mouseover", ".plex-control-group", function () {
        $(this).addClass("plex-control-focus");
      })
      .on("mouseout", ".plex-control-group", function () {
        // only remove class if element
        const $container = $(this);
        const $el = $(document.activeElement);

        // remove the focus class unless the currently focussed element is within this container
        if (!$el.is(":input") || !$el.closest(".plex-control-group").is($container)) {
          $container.removeClass("plex-control-focus");
        }
      });
  }
  const login = new LoginController();

  login.init();
});

function launchDialog(dialogRoute, routeData) {
  self.dialog = new DialogController({
    route: buildUrl(dialogRoute),
    autoShow: true,
    routeData
  });
}
// TODO: Update this to use whatever standard validation scheme we adopt
// Until then, this will get the job done.
/* eslint-disable camelcase */
function quick_Validate() {
  // Keep track of the number of validation errors
  let validationErrors = 0;

  // Grab the fields for validation
  const userId = document.getElementById("userId");
  const password = document.getElementById("password");
  const companyCode = document.getElementById("companyCode");

  // Grab the labels for the validation fields
  const userIdErrorField = document.getElementById("userIdErrorField");
  const passwordErrorField = document.getElementById("passwordErrorField");
  const companyCodeErrorField = document.getElementById("companyCodeErrorField");

  // Validating in reverse order will "float" focus to the highest-precedence field.
  // Check if a company code was entered.
  if (companyCode.value === "") {
    quick_Validate_Show_Error_Text(
      plex.login.getGlossarizedWord("ErrorMissingCompanyCode"),
      companyCode,
      companyCodeErrorField
    );
    validationErrors++;
  } else {
    quick_Validate_Hide_Error_Text(companyCodeErrorField);
  }

  // Check if a password was entered.
  if (password.value === "") {
    quick_Validate_Show_Error_Text(plex.login.getGlossarizedWord("ErrorMissingPassword"), password, passwordErrorField);
    validationErrors++;
  } else {
    quick_Validate_Hide_Error_Text(passwordErrorField);
  }

  // Check if a user ID was entered.
  if (userId.value === "") {
    quick_Validate_Show_Error_Text(plex.login.getGlossarizedWord("ErrorMissingUserID"), userId, userIdErrorField);
    validationErrors++;
  } else {
    quick_Validate_Hide_Error_Text(userIdErrorField);
  }

  // Call Update_Error_Classes to update all the absolute positioning on the page.
  update_Error_Classes(validationErrors);
  return validationErrors === 0;
}

// TODO: This function will likely be deleted when standard form validation is implemented
// instead of Quick_Validate,
function quick_Validate_Show_Error_Text(text, field, errorText) {
  errorText.innerHTML = text;
  errorText.className = errorText.className.replace(" invisible", "");
  field.focus();
}

// TODO: This function will likely be deleted when standard form validation is implemented
// instead of Quick_Validate.
function quick_Validate_Hide_Error_Text(element) {
  // Only add invisible if the the field is not already invisible.
  if (element.className.indexOf("invisible") === -1) {
    element.className = element.className += " invisible";
  }
}

// Create a function to update CSS classes for various fields based on errors being added to the page.
function update_Error_Classes(validationErrors) {
  // Create an array of all the scalable element IDs.
  const scalableElements = ["loginPageContainer", "loginPanelContainer", "login"];
  // Iterate through the scalable elements.
  for (let i = 0; i < scalableElements.length; i++) {
    // Grab the scalable element.
    const target = document.getElementById(scalableElements[i]);

    // Keep a hasMessage variable as we don't want to change whether or not a message is being displayed.
    const hasMessage = target.getAttribute("class").indexOf("Message") > -1;

    // Store all the base classes in an array so as to not accidentally delete any.
    const baseClasses = target
      .getAttribute("class")
      .split(" ")
      .filter((item) => {
        return item.indexOf("Has") === -1 ? item : "";
      });

    // Declare an empty replacement class variable.
    let replacementClass = "";

    // Check if any classes will be added.
    if (hasMessage || validationErrors > 0) {
      // As long as a class is going to be added, it will start with the name of the element and "Has".
      replacementClass = scalableElements[i] + "Has";

      // If there is a message, add the message modifier.
      if (hasMessage) {
        replacementClass += "Message";
      }

      // If there are validation errors, add Error plus the number of them.
      if (validationErrors > 0) {
        replacementClass += "Error" + validationErrors;
      }
    }

    // Finally, recombine the classes and set the class attribute of the target element to the new string.
    const finalClasses = baseClasses.join(" ") + " " + replacementClass;
    target.setAttribute("class", finalClasses);
  }
}
/* eslint-enable camelcase */
